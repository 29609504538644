import React from 'react';

const Footer = () => {
    return (
        <footer>
                <h2 style={{color: 'white'}}>Developed With ❤️ By <span className='rainbow'>Vinit</span></h2>
        </footer>
    );
}

export default Footer;
